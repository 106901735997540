import { render, staticRenderFns } from "./pago-confirming.vue?vue&type=template&id=407a4b60&scoped=true&"
import script from "./pago-confirming.vue?vue&type=script&lang=ts&"
export * from "./pago-confirming.vue?vue&type=script&lang=ts&"
import style0 from "./main.css?vue&type=style&index=0&id=407a4b60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407a4b60",
  null
  
)

export default component.exports