



























































import { RouterNames } from "@/router/routernames";
import { API } from "@/shared/api";
import { ssmHttpService } from "@/shared/services/http-service";
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class EmailConfirming extends Vue {
  created() {
    let tkn = this.$route.params.token;
    ssmHttpService
      .post(API.PagosStripe + "/validate/" + tkn, null, false, false, true)
      .then(() => this.routeToConfirmEmail())
      .catch((err: string) => {});
  }

  private routeToConfirmEmail() {
    this.$router.push({
      name: RouterNames.Login,
      params: { registro: "true" }
    });
  }
}
